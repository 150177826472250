import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {DOWNLOADLINK} from "./Home.js"

const MainNavBar = ({option, chooseOption, closeSession, user}) => {
    return (
        <Fragment>
            <nav className="navbar navbar-expand-md navbar-dark py-3 bg-terra" id="mainNav">
                <div className="mx-auto order-0">
                    <Link className="navbar-brand" to="/"><img src="/assets/img/portfolio/fullsize/logo.svg" height="70px" alt="Logo" /> TERRA</Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target=".dual-collapse2">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="navbar-collapse collapse order-3 dual-collapse2">
                    <ul className="navbar-nav ml-auto">
                        {/* <li className="nav-item">
                            <button type="button"
                                onClick={() => chooseOption('list-documents')}
                                className={ option === 'list-documents' ? 'nav-link btn btn-link active': 'nav-link btn btn-link'}
                            >Documents</button>
                        </li> */}
                        <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
                        <li className="nav-item"><a className="nav-link" href={DOWNLOADLINK}>Download</a></li>
                        <li className="nav-item">
                            <button type="button"
                                onClick={() => chooseOption('upload-zip')}
                                className={ option === 'upload-zip' ? 'nav-link btn btn-link active': 'nav-link btn btn-link'}
                            >Upload Zip</button>
                        </li>
                        {/* <li className="nav-item">
                            <button type="button"
                                onClick={() => chooseOption('about')}
                                className={ option === 'about' ? 'nav-link btn btn-link active': 'nav-link btn btn-link'}
                            >About</button>
                        </li> */}
                        <li className="nav-item">
                            <button type="button"
                                onClick={() => closeSession()}
                                className='nav-link btn btn-link'
                            >Logout</button>
                        </li>
                    </ul>
                </div>
            </nav>
        </Fragment>
    );
};

export default MainNavBar;
