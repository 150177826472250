import React from 'react';
import PropTypes from 'prop-types';
import DocumentDefinitions from './DocumentDefinitions.js';
import DocumentTextEditor from './DocumentTextEditor.js';

const Document = ({openedDocument, editDocument}) => {

        const {name, id, author, created, modified, workEditor, terraInput} = openedDocument;
        
        const updateOpenedDocument = doc => {
            // Every time the doc is updated will update modified date
            doc = {
                ...doc,
                modified: new Date().toUTCString()
            }
            // Also, after the doc is changed, update terra input to ensure last representation of the document
            doc = refreshTerraInputText(doc)

            editDocument(doc)
        }

        const updateState = e => {
            updateOpenedDocument({
                ...openedDocument,
                [e.target.name]: e.target.value
            })
        }
        const parseWorkEditor = text => {
            var newText = text.replace(/<p>/g,"");
            newText = newText.replace(/<\/p>/g,"");
            newText = newText.replace(/<newexec>/g,"\\newexe{}. ");
            newText = newText.replace(/<\/newexec>/g,"");
            return `\n${newText}\n`;
        }

        const refreshTerraInputText = (doc) => {
            const latexText = `\\documentclass[${doc.terraInput.documentclass.properties}]{${doc.terraInput.documentclass.type}}
${doc.terraInput.inputs.map(inp => `\\input{${inp}}`)}
               
\\begin{document}

${doc.terraInput.variaDef.length > 0 ? doc.terraInput.variaDef.map(v => `${v.s}`).join('\n'): ""}
${doc.terraInput.variaCalc.length > 0 ? doc.terraInput.variaCalc.map(v => `${v.s}`).join('\n'): ""}   
${parseWorkEditor(doc.workEditor)}
\\end{document}`;
            console.log(`Updating text to ${latexText}`)
            return {
                ...doc,
                terraInput: {
                    ...doc.terraInput,
                    freeText: latexText
                }
            }
        }
        const updateWorkEditorText = text => {
            let newDoc = openedDocument;
            newDoc = refreshTerraInputText({
                ...newDoc,
                workEditor: text
            })
            editDocument(newDoc);
        }

        const submitChanges = e => {
            e.preventDefault();

            editDocument(openedDocument);
        }
        return (<div className='document'>
            <h2>Editing Document</h2>
            <form
                onSubmit={submitChanges}
            >
                <center><h3>Metadata</h3></center>
                <b>Document Name:   </b>
                <input 
                    type="text"
                    name="name"
                    // className="u-full-width"
                    placeholder="Document Name"
                    onChange={updateState}
                    value={name}
                />
                <p><b>Author:</b> {author}, <b>Created:</b> {created}, <b>Last Modified:</b> {modified}, <b>ID:</b> {id}</p>
                <center><h3>Definitions</h3></center>
                <DocumentDefinitions 
                    document={openedDocument}
                    updateOpenedDocument={updateOpenedDocument}
                />
                <center><h3>Document content</h3></center>
                <label>Work editor:</label>
                <DocumentTextEditor
                    data={workEditor}
                    updateWorkEditorText={updateWorkEditorText}  
                />
                <label>TERRA input (Preview, not editable):</label>
                <textarea 
                    name="terraInput"
                    className="u-full-width"
                    //onChange={updateState}
                    value={terraInput.freeText}
                    disabled></textarea>
                <button
                    type="submit"
                    className="button u-button u-full-width"
                    onClick={() => editDocument(openedDocument)}
                    >Save <span role="img" aria-label="disk icon">&#128190;</span>
                </button>
            </form>
        </div>);
};

Document.propTypes = {
    openedDocument: PropTypes.object.isRequired,
    editDocument: PropTypes.func.isRequired,
}

export default Document;