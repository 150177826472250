import React from 'react';
import styled from '@emotion/styled';

const ContenedorHeader = styled.header`
  background-color: #dd8c5b;;
  padding: 10px;
  font-weight: bold;
  color: #FFFFFF;
`;

const TextoHeader = styled.h1`
  font-size: 4rem;
  margin: 0;
  font-family: 'Slabo 27px', serif;
  text-align: center;
`;

const Header = () => {
    // return (
    //     <ContenedorHeader>
    //       <TextoHeader>Welcome to TERRA</TextoHeader>
    //     </ContenedorHeader>
    // );
    return (
      <div className="container-fluid bg-primary text-white">
        <center><h1>Welcome to TERRA</h1></center>
      </div>
  );
};

export default Header;
