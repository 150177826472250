import React from 'react';

const DocumentVariableDef = ({variable, deleteDefVariable}) => {
    return (
        <div className="row">
            <div className="one-third column">
                {variable.name}
            </div>
            <div className="one-third column">
                {variable.interval}
            </div>
            <div className="one-third column">
                <button
                        // className="button u-button u-full-width"
                        onClick={() => deleteDefVariable(variable.id)}
                        >Delete Variable &times;
                </button>
            </div>
        </div>
    );
};

export default DocumentVariableDef;