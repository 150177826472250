import React, {Fragment, useState, useEffect} from 'react';
import DocumentList from './components/DocumentList.js'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './components/auth/Login';
import NewAccount from './components/auth/NewAccount';
import Home from './components/Home';
import DownloadPDF from './components/DownloadPDF';
import WorkSpace from './components/WorkSpace';
import AuthState from './context/authentication/authState';
import authToken from './config/authToken';
import DocumentState from './context/documents/documentState';


const token = localStorage.getItem('token');
if (token) {
  authToken(token);
}
function App() {
  // Documentos en local storage
  let initialDocuments = JSON.parse(localStorage.getItem('documents'));
  if(!initialDocuments){
    initialDocuments = [];
  }

  // State to store all the documents associated with the user
  const [documents, saveDocuments] = useState(initialDocuments);

  // Use Effect para realizar ciertas operaciones cuando el state cambia
  useEffect( () => {
    if (initialDocuments) {
      localStorage.setItem('documents', JSON.stringify(documents))
    } else {
      localStorage.setItem('documents', JSON.stringify([]));
    }
  }, [documents,initialDocuments]);

  // State selected menu option
  const [option, setOption] = useState('None');
  // State to store opened document
  const [openedDocumentId, saveOpenDocument] = useState(-1);

  const getOpenedDocument = () => {
    return documents.filter(doc => doc.id === openedDocumentId)[0];
  }
  // Functions to edit, add and remove documents
  const editDocument = document => {
    const newDocuments = documents.filter(doc => doc.id !==document.id);
    console.log('Saving changes...');
    console.log(document);
    saveDocuments([
      ...newDocuments,
      document
    ]);
  }
  const newDocument = document => {
    saveDocuments([
      ...documents,
      document
    ]);
  }
  const removeDocument = id => {
    console.log(`Removing document with id ${id}`);
    const newDocuments = documents.filter(doc => doc.id!==id);
    saveDocuments(newDocuments);
  }

  // Function to render the view of the current selected option of the menu
  const chooseOption = op => {
    console.log(`Selected option ${op}`);
    setOption(op);
  };

  // Function to open a Document
  const openDocument = id => {
    saveOpenDocument(id);
    console.log(`opening document with id ${id}`)
    chooseOption(`open-document`);
  }
  const documentList = () => (
    <DocumentList
      documents={documents}
      openDocument={openDocument}
      newDocument={newDocument}
      removeDocument={removeDocument}
    />
  );
  return (
    <AuthState>
      <DocumentState>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/e/:id" component={DownloadPDF} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={NewAccount} />
            <Route exact path="/terra" component={WorkSpace} />
          </Switch>
        </Router>
      </DocumentState>
    </AuthState>
    // <Fragment>
    //   <Header />
    //   <div className="container">
    //     <div className="row">
    //       <div className="one-third column">
    //         <MenuBar chooseOption={chooseOption} />
    //       </div>
    //       <div className="two-thirds column">
    //         { option === 'list-documents' ?
    //           <DocumentList
    //             documents={documents}
    //             openDocument={openDocument}
    //             newDocument={newDocument}
    //             removeDocument={removeDocument}
    //           />
    //         : option === 'open-document' ?
    //           <Document
    //             openedDocument={getOpenedDocument()}
    //             editDocument={editDocument}
    //           />
    //         : <p>Welcome to TERRA service. The new way of teaching and learning. You can go through the left menu to navigate to the different pages.</p>
    //         }
    //       </div>
    //     </div>
    //   </div>
    //   <Footer fecha={new Date().getFullYear()}/>
    // </Fragment>
  );
}

export default App;
