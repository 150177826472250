import React, { useState, useReducer, useEffect } from 'react';
import DocumentContext from './documentContext';
import DocumentReducer from './documentReducer';

import axiosClient from '../../config/axios';

import {
    ADD_DOCUMENT_ERROR,
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_ERROR,
    GET_ALL_DOCUMENTS,
    ADD_DOCUMENT
} from '../../types';

const DocumentState = props => {
    const initialState = {
        documents: [],
        error: false,
        messageError: '',
        documentCreated: false,
    }

    useEffect(() => {
        getDocuments();
    }, [])

    const [ state, dispatch ] = useReducer(DocumentReducer, initialState);
    
    const getDocuments = async () => {
        try {
            const answer = await axiosClient.get('/api/documents');
            dispatch({
                type: GET_ALL_DOCUMENTS,
                payload: answer.data.documents
            })
        } catch (error) {
            console.log(error);
        }
    }

    const addDocument = async document => {
        try {
            console.log('adding document...');
            console.log(document);
            const answer = await axiosClient.post('/api/documents', document);
            console.log(answer.data);
            dispatch({
                type: ADD_DOCUMENT,
                payload: answer.data
            })
        } catch (error) {
            console.log(error.response.data.msg);
            dispatch({
                type: ADD_DOCUMENT_ERROR,
                payload: error.response.data.msg
            })
        }
    }

    const deleteDocument = async id => {
        try {
            console.log('removing document...');
            console.log(id);
            const answer = await axiosClient.delete(`/api/documents/${id}`);
            dispatch({
                type: DELETE_DOCUMENT,
                payload: id
            })
        } catch (error) {
            console.log(error.response.data.msg);
            dispatch({
                type: DELETE_DOCUMENT_ERROR,
                payload: error.response.data.msg
            })
        }
    }
    
    return(
        <DocumentContext.Provider
            value={{
                documents: state.documents,
                error: state.error,
                messageError: state.messageError,
                documentCreated: state.documentCreated,
                addDocument,
                getDocuments,
                deleteDocument
            }}
        >{props.children}
        </DocumentContext.Provider>
    )
 }

 export default DocumentState;