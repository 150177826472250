import React, {Fragment} from 'react';
import {Link, withRouter} from 'react-router-dom';
import Footer, {Contact} from './Footer.js'

const Download = (props) => {
    const url = process.env.REACT_APP_BACKEND_URL + '/download/pdf/' + props.match.params.id;
    return (
        <Fragment>
                    {/* <!-- Navigation--> */}
            <nav className="navbar navbar-expand-lg navbar-light py-3 bg-terra" id="mainNav">
                <div className="container">
                    <Link className="navbar-brand js-scroll-trigger" to="/"><img src="/assets/img/portfolio/fullsize/logo.svg" height="70px" alt="Logo" /> TERRA - PDF Download</Link>
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ml-auto my-2 my-lg-0">
                            <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/login">Sign up / Log in</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
            <section className="page-section" id="download">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col text-center">
                            <h2 className="mt-0">Prepared PDF Document</h2>
                            <hr className="divider my-4" />
                            <p className="text-muted mb-5">You can find your document here: <a href={url}>PDF</a>.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Footer--> */}
            <Contact />
            <Footer />
            {/* <!-- Bootstrap core JS-->
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
            <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.bundle.min.js"></script> */}
            {/* <!-- Third party plugin JS--> */}
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
            {/* <!-- Core theme JS--> */}
            <script src="js/scripts.js"></script>
            {/* <!-- Font Awesome icons (free version)--> */}
            <script src="https://use.fontawesome.com/releases/v5.13.0/js/all.js" crossorigin="anonymous"></script>
            {/* <!-- Google fonts--> */}
            <link href="https://fonts.googleapis.com/css?family=Merriweather+Sans:400,700" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Merriweather:400,300,300italic,400italic,700,700italic" rel="stylesheet" type="text/css" />
            {/* <!-- Third party plugin CSS--> */}
            <link href="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/magnific-popup.min.css" rel="stylesheet" />
            {/* <!-- Core theme CSS (includes Bootstrap)--> */}
            <link href="/css/styles.css" rel="stylesheet" />
        </Fragment>
    );
};

export default withRouter(Download);
