import {
    ADD_DOCUMENT,
    DELETE_DOCUMENT,
    ADD_DOCUMENT_ERROR,
    GET_ALL_DOCUMENTS
} from '../../types';

export default (state, action) => {
    switch(action.type) {
        case ADD_DOCUMENT:
            return {
                ...state,
                documents: [ ...state.documents, action.payload ],
                documentCreated: true,
                error: false,
                messageError: ''
            }
        case ADD_DOCUMENT_ERROR:
            return {
                ...state,
                error: true,
                documentCreated: false,
                messageError: action.payload
            }
        case DELETE_DOCUMENT:
            console.log(action.payload);
            const newDocuments = state.documents.filter(doc => doc._id !== action.payload)
            localStorage.setItem('documents', JSON.stringify(newDocuments))
            return {
                ...state,
                documents: newDocuments
            }
        case GET_ALL_DOCUMENTS:
            localStorage.setItem('documents', JSON.stringify(action.payload));
            return {
                ...state,
                documents: action.payload,
                error: false
            }
        default:
            return state;
    }
}