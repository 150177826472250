import React, {Fragment, useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Form} from 'react-bootstrap';
import AuthContext from '../../context/authentication/authContext';
import Footer from '../Footer.js';
import {COUNTRIES} from '../../data/countries.js';

const NewAccount = (props) => {
	const [errors, setErrors] = useState({});
	const [validated, setValidated] = useState(false);
    const authContext = useContext(AuthContext);
    const { authenticated, registerUser } = authContext;
    // State para iniciar sesión
    const [usuario, guardarUsuario] = useState({
        name: '',
        email: '',
        password: '',
        password2: '',
        countryid: '',
        levelid: 999,
        institutionname: ''
    });
    useEffect(() => {
        if(authenticated){
            props.history.push('/terra');
        }
    })
    // extraer de usuario
    const { name, email, password, password2, countryid, levelid, institutionname } = usuario;


    const onChange = e => {
        guardarUsuario({
            ...usuario,
            [e.target.name]: e.target.value
		});
    };

	const checkPasswords = e => {
		guardarUsuario({
            ...usuario,
            [e.target.name]: e.target.value
		});

		if ((e.target.name === "password" && e.target.value !== password2)
			|| (e.target.name === "password2" && e.target.value !== password)) {
            console.log("Passwords don't match");
			setErrors({...errors, password2: "Passwords do not match"})
        } else {
			setErrors({...errors, password2: null})
            console.log("Passwords OK");
		}
	}

    const onSubmit = e => {
		const registerForm = e.currentTarget;
        e.preventDefault();

        if(email.trim() === '' || password.trim() === ''){
            console.log("email or password is empty");
			setErrors({...errors, basic: "Email or password missing"});
        } else {
			setErrors({...errors, basic: null});
		}
		
        if(password2.trim() === '' || password2 !== password){
            console.log("passwords do not match");
			setErrors({...errors, password2: "Passwords do not match"});
			return false;
        } else {
			setErrors({...errors, password2: null})
		}

        if(institutionname.trim() === '' ){
            console.log("Institution name is empty");
        }

        var country = {id: countryid, desc: COUNTRIES[countryid]};

        const LEVEL = {
          "100": "Primary School",
          "200": "Secondary School",
          "250": "College",
          "300": "College",
          "310": "University (Grade)",
          "350": "University (Postgraduate)",
          "400": "Professional Development",
          "500": "Profesoral",
          "800": "Official Licenses and Certifications",
          "999": "Other"
        }
        var institution = {level: {l: levelid, desc: LEVEL[levelid]}, desc: institutionname};

		if (registerForm.checkValidity() === false || errors.basic || errors.password2) {
			console.log("Invalid form")
			return false;
		} else {
			console.log("Submitting form")
			registerUser({name, email, password, country, institution});
		}
    }

    return (
      <Fragment>
          {/* <!-- Navigation--> */}
          <nav className="navbar navbar-expand-lg navbar-light py-3 bg-terra" id="mainNav">
              <div className="container">
                  <Link className="navbar-brand js-scroll-trigger" to="/"><img src="/assets/img/portfolio/fullsize/logo.svg" height="70px" alt="Logo" /> TERRA</Link>
                  <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="true" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                  <div className="collapse navbar-collapse" id="navbarResponsive">
                      <ul className="navbar-nav ml-auto my-2 my-lg-0">
                      <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
                      <li className="nav-item"><Link className="nav-link" to="/download">Download</Link></li>
                      </ul>
                  </div>
              </div>
          </nav>
          <div className="text-center my-3">
            <h2>Sign up to TERRA</h2>
          </div>
          <div className="container py-lg-3 px-5">
            <div className="card">
                <div className="card-header text-terra">User details</div>
                <div className="card-body">
                  <Form onSubmit={onSubmit} validated={validated}>
                    <div className="campo-form form-group">
                      <Form.Label htmlFor="email" className="control-label">Email</Form.Label>
                      <Form.Control
                          type="email"
                          id="email"
                          name="email"
                          placeholder="YourEmail@example.com"
                          onChange={onChange}
                          value={email}
                          className="form-control"
                          required="required"
                      />
                    </div>
                    <div className="campo-form form-group">
                        <Form.Label htmlFor="password" className="control-label" required="required">Password (6 characters min.)</Form.Label>
                        <Form.Control
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Your Password"
							onChange={checkPasswords}
                            value={password}
                            className="form-control"
                            required="required"
							minlength="6"
                        />
                    </div>
                    <div className="campo-form form-group">
                        <Form.Label htmlFor="password2" className="control-label">Repeat Password</Form.Label>
                        <Form.Control
                            type="password"
                            id="password2"
                            name="password2"
                            placeholder="Repeat Your Password"
							onChange={checkPasswords}
                            value={password2}
                            className="form-control"
                            required="required"
							isInvalid={!!errors.password2}
                        />
						<Form.Control.Feedback type="invalid">{errors.password2}</Form.Control.Feedback>
                    </div>
                    <div className="campo-form form-group">
                      <Form.Label htmlFor="name" className="control-label">Name</Form.Label>
                      <Form.Control
                        type="text"
                        id="name"
                        name="name"
                        placeholder="(Treatment) First Last"
                        onChange={onChange}
                        required="required"/>
                    </div>
                    <div className="campo-form form-group">
                      <Form.Label htmlFor="country" className="control-label">Country</Form.Label>
                      <Form.Control as="select" id="countryid" name="countryid" className="form-control" onChange={onChange}>
                          {Object.entries(COUNTRIES).map(([iso, name], idx) => {
                            return(<option value={iso}>{name}</option>);
                          })}
                      </Form.Control>
                    </div>
                    <div className="campo-form form-group">
                      <Form.Label htmlFor="level" className="control-label">Main use for TERRA</Form.Label>
                      <Form.Control as="select" id="levelid" name="levelid" className="form-control" onChange={onChange}>
                      <option value="100">Primary School</option>
                      <option value="200">Secondary School</option>
                      <option value="250">College</option>
                      <option value="300">College</option>
                      <option value="310">University (Grade)</option>
                      <option value="350">University (Postgraduate)</option>
                      <option value="400">Professional Development</option>
                      <option value="500">Profesoral</option>
                      <option value="800">Official Licenses and Certifications</option>
                      <option value="999">Other</option>
                      </Form.Control>
                    </div>
                    <div className="campo-form form-group">
                      <Form.Label htmlFor="institutionname" className="control-label">Institution</Form.Label>
                      <Form.Control
                        type="text"
                        id="institutionname"
                        name="institutionname"
                        placeholder="Institution name or denomination"
                        onChange={onChange}
                        required="required"
                      />
                    </div>
                    <p>* All fields are required</p>
                    <div className="campo-form text-center">
                        <Form.Control type="submit" className="btn btn-primary" value="Create" />
                    </div>
                  </Form>
                </div>
              </div>
          </div>
          <div className="pt-4 text-center">
            <hr className="divider" />
            <p>You can reach the TERRA team for technical help at <a href="mailto:support@terradocument.com">support@terradocument.com</a>.<br />
            For other inquiries please reach us at <a href="mailto:contact@terradocument.com">contact@terradocument.com</a>.</p>
          </div>
          <Footer />
          {/* <!-- Bootstrap core JS-->
          <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
          <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.bundle.min.js"></script> */}
          {/* <!-- Third party plugin JS--> */}
          <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.min.js"></script>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
          {/* <!-- Core theme JS--> */}
          <script src="js/scripts.js"></script>
          {/* <!-- Font Awesome icons (free version)--> */}
          <script src="https://use.fontawesome.com/releases/v5.13.0/js/all.js" crossorigin="anonymous"></script>
          {/* <!-- Google fonts--> */}
          <link href="https://fonts.googleapis.com/css?family=Merriweather+Sans:400,700" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css?family=Merriweather:400,300,300italic,400italic,700,700italic" rel="stylesheet" type="text/css" />
          {/* <!-- Third party plugin CSS--> */}
          <link href="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/magnific-popup.min.css" rel="stylesheet" />
          {/* <!-- Core theme CSS (includes Bootstrap)--> */}
          <link href="/css/styles.css" rel="stylesheet" />
      </Fragment>
    );
};

export default NewAccount;
