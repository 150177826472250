import React, {Fragment} from 'react';

const Footer = () => {
    return (
      <Fragment>
        <footer className="py-3 bg-terra">
            <center><p className="text-white">Created by the <strong>TERRA</strong> team. All rights reserved &copy; {new Date().getFullYear()} </p></center>
        </footer>
        {/* <!-- Core theme CSS (includes Bootstrap)--> */}
        <link href="/css/styles.css" rel="stylesheet" />
        {/* document.body.classList.add('bg-terra') */}
      </Fragment>
    );
};

export const Contact = () => {
  return(
    <div className="pt-4 text-center">
      <hr className="divider" />
      <p>For technical help please reach the TERRA team at <a href="mailto:support@terradocument.com">support@terradocument.com</a>.<br />
      For other inquiries please reach us at <a href="mailto:contact@terradocument.com">contact@terradocument.com</a>.</p>
    </div>
)};

export default Footer
