export const REGISTER_SUCCESSFULLY = 'REGISTERED_SUCCESSFULLY'
export const REGISTER_ERROR = 'REGISTERED_ERROR'
export const GET_USER = 'GET_USER'
export const LOGGIN_SUCCESSFULLY = 'LOGGIN_SUCCESSFULLY'
export const LOGGIN_ERROR = 'LOGGIN_ERROR'
export const CLOSE_SESSION = 'CLOSE_SESSION'
export const GET_ALL_DOCUMENTS = 'GET_ALL_DOCUMENTS'
export const ADD_DOCUMENT = 'ADD_DOCUMENT'
export const ADD_DOCUMENT_ERROR = 'ADD_DOCUMENT_ERROR'
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT'
export const DELETE_DOCUMENT_ERROR = 'DELETE_DOCUMENT_ERROR'