import React from 'react';

const DocumentVariableCalc = ({variable, deleteCalcVariable}) => {
    return (
        <div className="row">
            <div className="one-third column">
                {variable.name}
            </div>
            <div className="one-third column">
                {variable.expression}
            </div>
            <div className="one-third column">
                <button
                        // className="button u-button u-full-width"
                        onClick={() => deleteCalcVariable(variable.id)}
                        >Delete Variable &times;
                </button>
            </div>
        </div>
    );
};

export default DocumentVariableCalc;