import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import Footer from './Footer.js'

export const DOWNLOADLINK = 'https://www.terradocument.com/download/TERRAEditor_setup_1.0.0.146.msi';
const Home = () => {
    return (
        <Fragment>
            {/* <!-- Navigation--> */}
            <nav className="navbar navbar-expand-lg navbar-light py-3 bg-terra" id="mainNav">
                <div className="container">
                    <a className="navbar-brand js-scroll-trigger" href="#page-top"><img src="/assets/img/portfolio/fullsize/logo.svg" height="70px" alt="Logo" /> TERRA</a>
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="true" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ml-auto my-2 my-lg-0">
                            <li className="nav-item"><a className="nav-link" href={DOWNLOADLINK}>Download</a></li>
                            <li className="nav-item"><Link className="nav-link" to="/login">Sign up / Log in</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
            {/* <!-- Masthead--> */}
            <header className="masthead">
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-center text-center">
                        <div className="col-lg-10 align-self-end">
                            <h1 className="text-uppercase text-white font-weight-bold">Assessing the Learning Process</h1>
                            <hr className="divider my-4" />
                        </div>
                        <div className="col-lg-8 align-self-baseline">
                            <p className="text-white-75 font-weight-light mb-2"><strong>TERRA</strong> helps the teaching community create, from a single template, multiple variable exams, lab guides, exercise collections and even books.</p>
                            <p className="text-white-75 font-weight-light mb-5">With one click you will generate PDFs usable for both online and offline activities.</p>
                            <div className="container">
                              <div className="row">
                                <div className="col"><a className="btn btn-primary btn-xl js-scroll-trigger" href="#about">Find Out More</a></div>
                                <div className="col"><a className="btn btn-primary btn-xl js-scroll-trigger" href="#try-it-out">Try It Out</a></div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* <!-- About--> */}
            <section className="page-section" id="about">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                            <h2 className="text-strong mt-0"><strong>TERRA</strong> is developed by teachers, for the teaching community at large</h2>
                            <hr className="divider light my-4" />
                            <p className="text-muted mb-4">TERRA generates, from a single template, a collection of customized PDF documents including randomized (<em>i.e.</em>, different for each participant) content to prepare exams, quizzes, exercise sheets, laboratory guides, training assessments, booklets, etc.</p>
                            <p className="text-muted mb-4">Exercises can be designed to offer open or multiple choice answers. A solutions table with the correct answers for each PDF instance is also generated.</p>
                            <p className="text-muted mb-4">The TERRA Platform will offer<sup className="text-muted" id="ref1">[<a className="text-muted" href="#underconstruction" title="Under construction">1</a>]</sup> the possibility to carry out the test online. In this case the solutions table will be automatically filled out, including grading aids, upon submission of each participant answers. The solutions table can be easily edited with the help of standard spreadsheet software.</p>
                            <p className="text-muted mb-4">TERRA has a strong commitment to simplicity, reliability and privacy.</p>
                        </div>
                    </div>
                  </div>
                  <div className="container bg-primary">
                    <div className="row bg-primary">
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-5">
                                <i className="fas fa-4x fa-gem text-primary mb-4"></i>
                                <h3 className="h4 text-white mb-2">Simplicity</h3>
                                <ul>
                                <li className="mb-3 text-white-75">PDF documents can be used indistinctly for online and offline (printouts) exams.</li>
                                <li className="mb-3 text-white-75">Easy to use and learn. Keeps authors in control of the document creation and grading processes.</li>
                                <li className="mb-3 text-white-75">Document templates are created and submitted using our <a className="text-white" href="#editor">intuitive editor</a> for Windows.</li>
                                <li className="mb-0 text-white-75">A solutions table, including gradings, is provided as a readily editable spreadsheet document.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-5">
                                <i className="fas fa-4x fa-laptop-code text-primary mb-4"></i>
                                <h3 className="h4 text-white mb-2">Reliability</h3>
                                <ul>
                                <li className="mb-3 text-white-75">By generating PDFs in advance, authors can thoroughly review and validate each individual exercise.</li>
                                <li className="mb-3 text-white-75">Contrary to most Virtual Learning Environments, <strong>TERRA</strong> detects errors and inconsistencies in the template document (<em>e.g.</em>, in mathematical formulae defining variables) at the time of creation, not at the time the exam is taken by students.</li>
                                <li className="mb-3 text-white-75">Under the hood, <strong>TERRA</strong> relies on two robust environments: the LaTeX typesetting system and the Fortran programming language.</li>
                                <li className="mb-0 text-white-75">Advanced users can control headings, footers, citations, ToCs, complex equations (including formula calculations), and more.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-5">
                                <i className="fas fa-4x fa-globe text-primary mb-4"></i>
                                <h3 className="h4 text-white mb-2">Privacy</h3>
                                <ul>
                                  <li className="mb-3 text-white-75">Each PDF instance can include a link to a unique web address for downloading and taking<sup className="text-white-75" id="ref2">[<a className="text-white-75" href="#underconstruction" title="Under construction">2</a>]</sup> the test.</li>
                                  <li className="mb-0 text-white-75">No participants' personal or institutional information is required.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Portfolio--> */}
            <div className="clearfix visible-xs-block"></div>
            <section className="page-section" id="try-it-out">
                <div className="container p-0">
                      <div className="row justify-content-center">
                        <div className="col" id="editor">
                          <h2 className="mb-0 text-center">TERRA Editor</h2>
                          <hr className="divider my-4" />
                          <p>With <strong>TERRA</strong>'s easy-to-use editor for Windows you can readily create your template and generate multiple document instances.</p>
                          <a className="portfolio-box" href={DOWNLOADLINK} target="_blank">
                            <img className="img-fluid" src="assets/img/portfolio/thumbnails/TERRA_Editor_640.png" alt="The TERRA Editor for Windows in action" loading="lazy" />
                            <div className="portfolio-box-caption">
                              <div className="project-category text-white-50">Editor for Windows</div>
                              <div className="project-name d-none">TERRA Editor for Windows</div>
                            </div>
                          </a>
                          <p>Click here to <a href={DOWNLOADLINK}>download the <strong>TERRA</strong> Editor</a>.</p>
                          <p className="text-muted "><em>Note</em>: The <strong>TERRA</strong> Editor for Windows requires Edge, the default web browser included in MS Windows recent versions as the replacement for Explorer.</p>
                        </div>
                        <div className="col" id="advanced">
                          <h2 className="mb-0 text-center">For advanced users</h2>
                          <hr className="divider my-4" />
                          <p>Users comfortable with LaTeX can edit their template and <Link to="/login">submit</Link> it to the <strong>TERRA</strong> server for instance generation.</p>
                          <pre style={{height: 375 +'px'}}>{`\\documentclass[12pt,a4paper]{article}
\\input{_terraPreamble.tex}

% Paragraph indentation:
\\setlength{\\parindent}{0.0cm}

\\begin{document}


%%%% Global vars %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

\\variainstanceID{"Alice","Bob","Charlie","Dave","Eve"}
\\variadef{pi}{}{3.141592653589793}
\\variaexport{pi}                        % Make it visible for all exercises


%%%% Header %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

\\hrule
\\vspace{0.5em}
{\\Large \\bf Minimal Demo}               \\\\
{\\small v.2021-12-13}                   \\\\[1em]
{\\bf Instance \\#\\varia{instanceNum} intended for \\varia{instanceID}}
\\vspace{0.5em}
\\hrule


%%%% Exercise %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
\\vspace{2em}
\\hrule
\\vspace{1em}
{\\bf Exercise \\newexe{}}

\\variadef{base}{r3}{10,20}
\\variadef{height}{r3}{1,10}

Calculate the area of the rectangle with sides (in arbitrary units) $\\varia{base}$ and $\\varia{height}$. Choose one answer:

\\variacalc{area}{r3}{ ={base}*{height} }

\\inichoice{}{}
\\choice{100} The area is $\\varia{area}$                % Max score => automatically identified as the correct answer
\\choice{}    The area is $\\wronganswer{}$
\\choice{}    The area is $\\wronganswer{}$
\\choice[sticky]{} None of the previous
\\finchoice{}


%%%% Exercise %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
\\vspace{2em}
\\hrule
\\vspace{1em}
{\\bf Exercise \\newexe{}}

\\varialist{word}{t}{"A","bird","sat","on","a","branch","of","a","tree"}
\\varialist[i_word]{wordClass}{t}{"article","noun","verb","preposition","article","noun","preposition","article","noun"}

What is the lexical type of the word \`\`\\varia{word}'' in the sentence \\\\
\`\`\\emph{A bird sat on a branch of a tree}''?                 \\\\
Choose one answer:

\\inichoice{}{}
\\choice{100} \\varia{wordClass}
\\choice{}    \\wronganswer{}
\\choice{}    \\wronganswer{}
\\finchoice{}


%%%% Exercise %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
\\vspace{2em}
\\hrule
\\vspace{1em}
{\\bf Exercise \\newexe{}}

\\variaimport{}                          % Import all visible vars available ("pi" in this case)
\\variadef{r}{r2}{1,10}

Calculate, in cm$^2$, the area of a circle of radius $\\varia{r}$~cm.

\\variacalc{area}{r2}{ ={pi}*{r}^2 }

\\openchoice{area}{}{}{}                 % Used in the solutions table to grade this exe


%%%% Exercise %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
\\vspace{2em}
\\hrule
\\vspace{1em}
{\\bf Exercise \\newexe{}}

Essay: Describe the key ideas of C.~Darwin's {\\it On the Origin of Species}.


%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
\\end{document}`}</pre>
                          <p>Find here the <a href="/assets/docs/TERRAcheatSheet.pdf"><strong>TERRA</strong> Cheat Sheet (PDF, 177 KB)</a>, and <Link to="/login">log in</Link> to the <strong>TERRA</strong> server to submit your template.</p>
                          <p>Full examples:</p>
                          <ol>
                            <li><a href="/assets/docs/demo1-Minimal.zip">Demo 1: Minimal (ZIP, 1 KB)</a><br />
                                <a href="/assets/docs/demo1-Minimal-out.zip">Output for Demo 1: Minimal (ZIP, 292 KB)</a>
                            </li>
                            <li><a href="/assets/docs/demo2-Exam.zip">Demo 2: Exam (ZIP, 626 KB)</a><br />
                                <a href="/assets/docs/demo2-Exam-out.zip">Output for Demo 2: Exam (ZIP, 2 MB)</a>
                            </li>
                            <li><a href="/assets/docs/demo3-Features.zip">Demo 3: Features (ZIP, 7 KB)</a><br />
                                <a href="/assets/docs/demo3-Features-out.zip">Output for Demo 3: Features (ZIP, 695 KB)</a>
                            </li>
                          </ol>
                        </div>
                      </div>
                  <div className="row no-gutters p-3">
                        <div className="col-lg-4 col-sm-6">
                            <a className="portfolio-box" href="/assets/docs/001-Alice-main.pdf">
                                <img className="img-fluid" src="assets/img/portfolio/thumbnails/Demo1_320.png" alt="Document for participant 1" loading="lazy" />
                                <div className="portfolio-box-caption">
                                    <div className="project-category text-white-50">Document</div>
                                    <div className="project-name">Document for participant 1</div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <a className="portfolio-box" href="/assets/docs/002-Bob-main.pdf">
                                <img className="img-fluid" src="/assets/img/portfolio/thumbnails/Demo2_320.png" alt="Document for participant 2" loading="lazy" />
                                <div className="portfolio-box-caption">
                                    <div className="project-category text-white-50">Document</div>
                                    <div className="project-name">Document for participant 2</div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <a className="portfolio-box" href="/assets/docs/003-Charlie-main.pdf">
                                <img className="img-fluid" src="/assets/img/portfolio/thumbnails/Demo3_320.png" alt="Document for participant 3" loading="lazy" />
                                <div className="portfolio-box-caption">
                                    <div className="project-category text-white-50">Document</div>
                                    <div className="project-name">Document for participant 3</div>
                                </div>
                            </a>
                        </div>
                  </div>
                </div>
            </section>
            <hr className="pt-4 divider" />
            <p id="underconstruction" className="text-muted text-left pl-2"><strong>[<a href="#ref1">1</a>, <a href="#ref2">2</a>]</strong> Some features, and in particular the ability to fill in exam answers online, are still under active development by the <strong>TERRA</strong> team.</p>
            {/* <!-- Footer--> */}
            <Footer />
            {/* <!-- Bootstrap core JS-->
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
            <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.bundle.min.js"></script>
            {/* <!-- Third party plugin JS--> */}
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
            {/* <!-- Core theme JS--> */}
            <script src="/js/scripts.js"></script>
            {/* <!-- Font Awesome icons (free version)--> */}
            <script src="https://use.fontawesome.com/releases/v5.13.0/js/all.js" crossOrigin="anonymous"></script>
            {/* <!-- Google fonts--> */}
            <link href="https://fonts.googleapis.com/css?family=Merriweather+Sans:400,700" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Merriweather:400,300,300italic,400italic,700,700italic" rel="stylesheet" type="text/css" />
            {/* <!-- Third party plugin CSS--> */}
            <link href="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/magnific-popup.min.css" rel="stylesheet" />
            {/* <!-- Core theme CSS (includes Bootstrap)-->
            <link href="/css/styles.css" rel="stylesheet" /> */}
        </Fragment>
    );
};

export default Home;
