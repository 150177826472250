import React, {useState} from 'react';
import uuid from 'uuid/v4'
import DocumentVariableDef from './DocumentVariableDef.js';
import DocumentVariableCalc from './DocumentVariableCalc.js';

const DocumentDefinitions = ({document, updateOpenedDocument}) => {
    const [freshDefVariable, addDefVariable] = useState({name: '', interval: ''});
    const [freshCalcVariable, addCalcVariable] = useState({name: '', expression: ''});

    const varDefName = freshDefVariable.name;
    const interval = freshDefVariable.interval;

    const varCalcName = freshCalcVariable.name;
    const expression = freshCalcVariable.expression;


    const variablesDef = document.variables.def;
    const variablesCalc = document.variables.calc;

    const {variables, terraInput } = document;
    const {variaDef, variaCalc} = terraInput;

    const deleteDefVariable = id => {
        let newVariables = variables.def.filter(v => v.id !== id);
        let newTerraVariables = variaDef.filter(v => v.id !== id);
        updateOpenedDocument({
            ...document,
            variables: {
                ...document.variables,
                def: newVariables
            },
            terraInput: {
                ...terraInput,
                variaDef: newTerraVariables,
            },
            modified: new Date().toUTCString()
        });
        
    }
    const deleteCalcVariable = id => {
        let newVariables = variables.calc.filter(v => v.id !== id);
        let newTerraVariables = variaCalc.filter(v => v.id !== id);
        updateOpenedDocument({
            ...document,
            variables: {
                ...document.variables,
                calc: newVariables
            },
            terraInput: {
                ...terraInput,
                variaCalc: newTerraVariables,
            },
            modified: new Date().toUTCString()
        });
    }
    const updateNewVariableDefState = e => {
        addDefVariable({
            ...freshDefVariable,
            [e.target.name]: e.target.value
        })
    }
    const updateNewVariableCalcState = e => {
        addCalcVariable({
            ...freshCalcVariable,
            [e.target.name]: e.target.value
        })
    }
    const addNewVariableDef = v => {
        const varId = uuid();
        updateOpenedDocument({
            ...document,
            variables: {
                def: [...document.variables.def, {...v, id: varId}],
                calc: document.variables.calc
            },
            terraInput: {
                ...terraInput,
                variaDef: [...variaDef, { id: varId, s: `\\variadef{${v.name}}{}{${v.interval}}`}],
            },
            modified: new Date().toUTCString()
        })
    }
    const addNewVariableCalc = v => {
        const varId = uuid();
        updateOpenedDocument({
            ...document,
            variables: {
                def: document.variables.def,
                calc: [...document.variables.calc, {...v, id: varId}]
            },
            terraInput: {
                ...terraInput,
                variaCalc: [...variaCalc, { id: varId, s: `\\variacalc{${v.name}}{}{{${v.name}} = ${v.expression}}`} ],
            },
            modified: new Date().toUTCString()
        })
    }

    return (
        <div>
            <h4>Non calculated variables</h4>
            {
            variablesDef.length === 0 ? <p>There is not any variable defined yet</p>:
                variablesDef.map(variable => (
                    <DocumentVariableDef
                        key={variable.id}
                        variable={variable}
                        deleteDefVariable={deleteDefVariable}
                    />
                ))
            }
            <input 
                type="text"
                name="name"
                // className="u-full-width"
                onChange={updateNewVariableDefState}
                placeholder="Variable Name"
                value={varDefName}
            />
            <input 
                type="text"
                name="interval"
                // className="u-full-width"
                onChange={updateNewVariableDefState}
                placeholder="Interval"
                value={interval}
            />
            <button
                // className="button u-button u-full-width"
                onClick={() => addNewVariableDef({id: uuid(), name: varDefName, interval: interval})}
                >Add Variable <span role="img" aria-label="disk icon">&#128190;</span>
            </button>
            <h4>Calculated variables</h4>
            {
            variablesCalc.length === 0 ? <p>There is not any variable defined yet</p>:
                variablesCalc.map(variable => (
                    <DocumentVariableCalc
                        key={variable.id}
                        variable={variable}
                        deleteCalcVariable={deleteCalcVariable}
                    />
                ))
            }
            <input 
                type="text"
                name="name"
                // className="u-full-width"
                onChange={updateNewVariableCalcState}
                placeholder="Variable Name"
                value={varCalcName}
            />
            <input 
                type="text"
                name="expression"
                // className="u-full-width"
                onChange={updateNewVariableCalcState}
                placeholder="expression"
                value={expression}
            />
            <button
                // className="button u-button u-full-width"
                onClick={() => addNewVariableCalc({id: uuid(), name: varCalcName, expression: expression})}
                >Add Variable <span role="img" aria-label="disk icon">&#128190;</span>
            </button>
        </div>
    );
};

export default DocumentDefinitions;