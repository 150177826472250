import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
//import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import DecoupledEditor from 'ckeditor5-build-decoupled-document';
import CKEditorInspector from '@ckeditor/ckeditor5-inspector';


const DocumentTextEditor = ({data, updateWorkEditorText}) => {
    return (
        <div className="document-editor">
            <div className="document-editor__toolbar">
                <div className="document-editor__editable-container">
                    <CKEditor
                        editor={ DecoupledEditor }
                        data={data}
                        config={ {
                            //toolbar: [ 'bold', 'italic', 'link' , 'newExec'],
                            // extraPlugins: [NewExec]
                        }}
                        onInit={ editor => {
                            window.editor = editor;
                            
                            // Add these two lines to properly position the toolbar
                            const toolbarContainer = document.querySelector( '.document-editor__toolbar' );
                            toolbarContainer.appendChild( editor.ui.view.toolbar.element );
                            CKEditorInspector.attach( editor );
                            // You can store the "editor" and use when it is needed.
                             console.log(Array.from( editor.ui.componentFactory.names() ));
                            console.log( 'Editor is ready to use!', editor );
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            updateWorkEditorText(data);
                            console.log( { event, editor, data } );
                        } }
                        onBlur={ ( event, editor ) => {
                            console.log( 'Blur.', editor );
                        } }
                        onFocus={ ( event, editor ) => {
                            console.log( 'Focus.', editor );
                        } }
                    />
                </div>
            </div>
        </div>
    );
};

export default DocumentTextEditor;