import React, {useContext, useState} from 'react';
import DocumentContext from '../context/documents/documentContext';
import AuthContext from '../context/authentication/authContext';


const DocumentCreation = () => {
    const authToken = useContext(AuthContext);
    const { user } = authToken;

    const [ newDocumentName, updateNewDocumentName]  = useState('');

    const documentContext = useContext(DocumentContext);
    const { addDocument, error, messageError, documentCreated } = documentContext;
  
    const createNewDocument = () => {
        const defaultProperties = '12pt,a4paper';
        const defaultType = 'article';
        const defaultInputs = ['terraPreamble.tex'];
        const latexText = `\\documentclass[${defaultProperties}]{${defaultType}}
${defaultInputs.map(inp => `\\input{${inp}}`)}

\\begin{document}

\\end{document}`   ;

        const newDoc = {
            name: newDocumentName,
            author: user.name,
            created: new Date().toUTCString(),
            modified: new Date().toUTCString(),
            variables: {
                def: [],
                calc: []
            },
            workEditor: '',
            terraInput: {
                documentclass: {
                    properties: defaultProperties,
                    type: defaultType
                },
                inputs: defaultInputs,
                freeText: latexText,
                variaDef: [],
                variaCalc: []
            }
        };
        console.log(newDoc);
        return newDoc;
    }

    return (
        <form
            onSubmit={ e => { e.preventDefault(); addDocument(createNewDocument()); }}
        >
            <div className="form-group">
                <label htmlFor="inputName">Document Name</label>
                <input 
                    type="text" 
                    className="form-control" 
                    id="inputName" 
                    onChange={e => {e.preventDefault(); updateNewDocumentName(e.target.value)}}
                    value={newDocumentName}
                    aria-describedby="nameHelp" 
                    placeholder="Enter Document Name" />
                <small id="nameHelp" className="form-text text-muted">Document name must be unique.</small>
            </div>
            { error ? 
                <div className="alert alert-danger" role="alert">
                    {messageError}
                </div>
            : null
            }
            { documentCreated ?
                <div className="alert alert-success" role="alert">
                    Document created successfully!
                </div>
            : null
            }
            <button type="submit" className="btn btn-primary">Create Document</button>
        </form>
    );
};

export default DocumentCreation;