import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import DocumentCreation from './DocumentCreation';
import DocumentContext from '../context/documents/documentContext';


const DocumentList = ({openDocument, newDocument}) => {
    // State to create a new Document
    const [freshDocumentName, updateFreshDocumentName] = useState('');

    const documentContext = useContext(DocumentContext);
    const { documents, deleteDocument } = documentContext;

    const updateState = e => {
        console.log(e.target.value)
        updateFreshDocumentName(e.target.value);
        //updateDocument(e);
    };

    useEffect(() => {
        console.log("documents updated")
    }, [documents])
    // Function to create a new Document
    const createNewDocument = e => {
        e.preventDefault();

        const defaultProperties = '12pt,a4paper';
        const defaultType = 'article';
        const defaultInputs = ['terraPreamble.tex'];
        const latexText = `\\documentclass[${defaultProperties}]{${defaultType}}
${defaultInputs.map(inp => `\\input{${inp}}`)}

\\begin{document}

\\end{document}`   ;
        newDocument({
            id: uuid(),
            name: freshDocumentName,
            author: 'Albert Palau',
            created: new Date().toUTCString(),
            modified: new Date().toUTCString(),
            variables: {
                def: [],
                calc: []
            },
            workEditor: '',
            terraInput: {
                documentclass: {
                    properties: defaultProperties,
                    type: defaultType
                },
                inputs: defaultInputs,
                freeText: latexText,
                variaDef: [],
                variaCalc: []
            }
        });
    }

    const name = freshDocumentName
    const divStyle = {
        maxWidth: '18rem',
        display: 'inline-block'
    };
    return (
        <div className="container mt-5">
            <h2>Create new document </h2>
                    <DocumentCreation />
            <div className="row mt-5">
                <div className="col-sm">
                    <h2>Document list </h2>
                    { documents.length > 0 ? documents.map( 
                        document => (
                            <div className="card bg-light mb-2 ml-2" style={divStyle} key={document._id}>
                                <div className="card-header">{document.name}</div>
                                <div className="card-body">
                                    <h5 className="card-title">Metadata</h5>
                                    <ul className="card-text">
                                            <li>Author Id: {document.author}</li>
                                            <li>Created: {document.created}</li>
                                            <li>Author: {document.modified}</li>
                                    </ul>
                                    <button type="button" className="btn btn-dark">Edit</button>
                                    <button 
                                        type="button" 
                                        className="btn btn-dark ml-2"
                                        onClick={() => deleteDocument(document._id)}
                                    >Remove</button>
                                </div>
                            </div>
                        )
                    ): 'There is not any document created yet.'}
                </div>
            </div>
        </div>
    );
};

DocumentList.propTypes = {
    openDocument: PropTypes.func.isRequired,
    newDocument: PropTypes.func.isRequired,
    removeDocument: PropTypes.func.isRequired
}

export default DocumentList;