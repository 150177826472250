import React, {useContext, useState, useEffect, Fragment} from 'react';
import FormData from 'form-data';
import axiosClient from '../config/axios';
import AuthContext from '../context/authentication/authContext';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const UploadZip = (props) => {
    const [ file, saveFile ] = useState({
        localFile: {
            name: 'Choose file'
        },
        instances: 3
    });
    const [ fileURL, saveFileURL ] = useState('');
    const [ zipErrors, setZipErrors ] = useState(0);
    const [ zipStdout, setZipStdout ] = useState([]);
    const [ zipStderr, setZipStderr ] = useState([]);
    const [ downloadMessage, setDownloadMessage ] = useState('');
    const [ pendingZip, setPendingZip ] = useState(false);

    const authToken = useContext(AuthContext);
    const { user, userAuthenticated, token } = authToken;

    const { localFile, instances } = file;

    useEffect(() => {
        userAuthenticated();
      }, [])

    if (!user) {
    props.history.push('/login');
    return null;
    }

    const uploadZip = async e => {
        e.preventDefault();
        setPendingZip(true);
        const formData = new FormData();
        formData.append("zipFile", localFile);
        formData.append("instances", instances);
        try {
            const answerCompile = await axiosClient.post('/internals/compileZip', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            });
            const resultZip = answerCompile.data.dir;
            const errors = answerCompile.data.errors;
            setZipErrors(errors);
            if (errors !== 0) {
                setDownloadMessage("Sorry, your document has errors. Click here to TRY TO download it");
            }else{
                setDownloadMessage("Your document is ready. Click to download.");
            }
            setZipStdout(answerCompile.data.messages);
            setZipStderr(answerCompile.data.stderr);
            const zipURL = `${process.env.REACT_APP_BACKEND_URL}/internals/compileZip?path=${resultZip}&xauthtoken=${token}`
            console.log(zipURL)
            saveFileURL(zipURL)
        } catch (error) {
            console.log(error)
        }
        setPendingZip(false);
    }

    return (
        <Fragment>
            <div className="container py-3 mx-auto">
              <div className="row">
                <h2 className="px-1">Upload a zip file with your <strong>TERRA</strong> project</h2>
                <ol>
                  <li className="pb-2">Your template must be named <code>main.tex</code>.</li>
                  <li className="pb-2">Your template must include this line:
                    <pre>{'\input{_terraPreamble.tex}'}</pre>
                    (The preamble file will be provided by the <strong>TERRA</strong> server).</li>
                  <li className="pb-2">Zip your <code>main.tex</code> and all the ancillary files, keeping the <code>main.tex</code> at the zip's root.</li>
                  <li className="pb-2">Upload the zip file containing your <strong>TERRA</strong> project and specify the number of instances you want to generate:</li>
                </ol>
              </div>
              <form
                  onSubmit={uploadZip}
              >
                <div className="row mt-5 w-100">
                  <div className="custom-file col-6">
                    <input type="file" className="custom-file-input" id="zipFile" onChange={ e => {
                      e.preventDefault();
                      console.log(e.target.files[0]);
                      saveFile({
                        ...file,
                        localFile: e.target.files[0]
                        });
                      }}/>
                    <span>(Max. 16 MB)</span>
                    <label className="custom-file-label" htmlFor="zipFile">{localFile.name}</label>
                  </div>
                  <div className="col-2">
                    <label className="form-label text-right" htmlFor="instances">Number of instances</label>
                  </div>
                  <div className="col-2">
                    <input type="number" className="form-control" id="instances" min="1" max="200" value={instances} placeholder="" onChange={ e => {
                        e.preventDefault();
                        saveFile({
                            ...file,
                            instances: e.target.value
                        })
                    }}/>
                  </div>
                  <div className="col py-auto">
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                </div>
              </form>
                { pendingZip ?
                    <Button variant="primary" disabled className="btn btn-primary mt-3">
                        <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />
                        Generating document...
                    </Button>
                :   null}
                { zipErrors !== 0 ?
                    <div>
                        <h3>Your zip has errors</h3>
                        <h2>Stdout</h2>
                        <div className="alert alert-danger" role="alert">
                            {zipStdout.map(function(item, idx) {
                                return (
                                    <span key={idx}>
                                        {item}
                                        <br/>
                                    </span>
                                )
                            })}
                        </div>
                        <h2>Stderr</h2>
                        <div className="alert alert-danger" role="alert">
                            {/*zipStderr.split('\n').map(function(item, idx) {
                                return (
                                    <span key={idx}>
                                        {item}
                                        <br/>
                                    </span>
                                )
                            })*/}
                            {zipStderr}
                        </div>
                    </div>
                :
                    null
                }
                { fileURL !== '' && !pendingZip ?
                    <a href={fileURL} target="_blank" download>
                        <button className="btn btn-primary mt-3">{downloadMessage}</button>
                    </a>
                    : null
                }
                {/*}<link
                    rel="stylesheet"
                    href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
                    integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
                    crossOrigin="anonymous"
                />*/}
            </div>
        </Fragment>
    );
};

export default UploadZip;
