import React, { useReducer } from 'react';
import AuthContext from './authContext';
import AuthReducer from './authReducer';

import axiosClient from '../../config/axios';
import authToken from '../../config/authToken';

import {
    REGISTER_SUCCESSFULLY,
    REGISTER_ERROR,
    GET_USER,
    LOGGIN_SUCCESSFULLY,
    LOGGIN_ERROR,
    CLOSE_SESSION
} from '../../types';

const AuthState = props => {
    const initialState = {
        token: localStorage.getItem('token'),
        authenticated: null,
        user: null,
        message: null
    }

    const [ state, dispatch ] = useReducer(AuthReducer, initialState);

    const registerUser = async data => {
        try {
            const answer = await axiosClient.post('/api/users', data);
            dispatch({
                type: REGISTER_SUCCESSFULLY,
                payload: answer.data
            });
            this.props.history.push("/terra");
        } catch (error) {
            console.log(error);
            dispatch({
                type: REGISTER_ERROR,
            })
        }
    }

    const userAuthenticated = async () => {
        const token = localStorage.getItem('token');
        if(token){
            authToken(token);
        }

        try {
            const answer = await axiosClient.get('/api/auth');

            dispatch({
                type: GET_USER,
                payload: answer.data.user
            })
        } catch (error){
            console.log(error);
            dispatch({
                type: LOGGIN_ERROR
            })
        }
    }
    const login = async data => {
        try {
            console.log("Trying to login")
            const answer = await axiosClient.post('/api/auth', data);
            dispatch({
                type: LOGGIN_SUCCESSFULLY,
                payload: answer.data
            });
            authToken(answer.data.token);
            userAuthenticated();
            // this.props.history.push("/terra");
        } catch (error){
            console.log(error);
            dispatch({
                type: LOGGIN_ERROR
            })
        }
    }

    const closeSession = async data => {
        dispatch({
            type: CLOSE_SESSION
        })
    }

    return(
        <AuthContext.Provider
            value={{
                token: state.token,
                authenticated: state.authenticated,
                user: state.user,
                message: state.message,
                login,
                userAuthenticated,
                closeSession,
                registerUser
            }}
        >{props.children}
        </AuthContext.Provider>
    )
 }

 export default AuthState;
