import React, {Fragment, useState, useEffect, useContext} from 'react';
import Header from './Header.js'
import MainNavBar from './MainNavBar.js'
import Document from './Document.js'
import DocumentList from './DocumentList.js'
import UploadZip from './UploadZip.js'
import Footer, {Contact} from './Footer.js'
import AuthContext from '../context/authentication/authContext';
import Iframe from 'react-iframe';

const WorkSpace = (props) => {
  const authToken = useContext(AuthContext);
  const { user, authenticated, userAuthenticated, closeSession } = authToken;

  // Documentos en local storage
  let initialDocuments = JSON.parse(localStorage.getItem('documents'));
  if(!initialDocuments){
    initialDocuments = [];
  }
  useEffect(() => {
    userAuthenticated();
  }, [])
  // State to store all the documents associated with the user
  const [documents, saveDocuments] = useState(initialDocuments);

  // Use Effect para realizar ciertas operaciones cuando el state cambia
  useEffect( () => {
    if (initialDocuments) {
      localStorage.setItem('documents', JSON.stringify(documents))
    } else {
      localStorage.setItem('documents', JSON.stringify([]));
    }
  }, [documents,initialDocuments]);

  // State selected menu option
  const [option, setOption] = useState('None');

  // State to store opened document
  const [openedDocumentId, saveOpenDocument] = useState(-1);

  if (!user) {
    props.history.push('/login');
    return null;
  }

  const getOpenedDocument = () => {
    return documents.filter(doc => doc.id === openedDocumentId)[0];
  }
  // Functions to edit, add and remove documents
  const editDocument = document => {
    const newDocuments = documents.filter(doc => doc.id !==document.id);
    console.log('Saving changes...');
    console.log(document);
    saveDocuments([
      ...newDocuments,
      document
    ]);
  }
  const newDocument = document => {
    saveDocuments([
      ...documents,
      document
    ]);
  }
  const removeDocument = id => {
    console.log(`Removing document with id ${id}`);
    const newDocuments = documents.filter(doc => doc.id!==id);
    saveDocuments(newDocuments);
  }

  // Function to render the view of the current selected option of the menu
  const chooseOption = op => {
    console.log(`Selected option ${op}`);
    setOption(op);
  };

  // Function to open a Document
  const openDocument = id => {
    saveOpenDocument(id);
    console.log(`opening document with id ${id}`)
    chooseOption(`open-document`);
  }
  const documentList = () => (
    <DocumentList
      documents={documents}
      openDocument={openDocument}
      newDocument={newDocument}
      removeDocument={removeDocument}
    />
  );

    return (
    <Fragment>
      <MainNavBar chooseOption={chooseOption} option={option} closeSession={closeSession} user={user}/>
            { option === 'list-documents' ?
              <DocumentList
                documents={documents}
                openDocument={openDocument}
                newDocument={newDocument}
                removeDocument={removeDocument}
              />
            : option === 'open-document' ?
              <Document
                openedDocument={getOpenedDocument()}
                editDocument={editDocument}
              />
            : option === 'upload-zip' ?
              <UploadZip />
            :
              <UploadZip />
            }
      <Contact />
      <Footer />
    </Fragment>
    );
};

export default WorkSpace;
