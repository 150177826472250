import React, {Fragment, useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import AuthContext from '../../context/authentication/authContext';
import {DOWNLOADLINK} from '../Home.js';
import Footer, {Contact} from '../Footer.js';

const Login = (props) => {

    const authContext = useContext(AuthContext);
    const {authenticated, login} = authContext;

    useEffect(() => {
        if(authenticated){
            props.history.push('/terra');
        }
    })
    // State para iniciar sesión
    const [usuario, guardarUsuario] = useState({
        email: '',
        password: '',
    });

    // extraer de usuario
    const { email, password } = usuario;

    const onChange = e => {
        guardarUsuario({
            ...usuario,
            [e.target.name]: e.target.value
        })
    };

    const onSubmit = e => {
        e.preventDefault();

        if(email.trim() === '' || password.trim() === ''){
            console.log("Email or password is empty");
        }

        login({email, password});
    }
    return (
      <Fragment>
      {/* <!-- Navigation--> */}
      <nav className="navbar navbar-expand-lg navbar-light py-3 bg-terra" id="mainNav">
          <div className="container">
          <Link className="navbar-brand js-scroll-trigger" to="/"><img src="/assets/img/portfolio/fullsize/logo.svg" height="70px" alt="Logo" /> TERRA</Link>
          <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="true" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto my-2 my-lg-0">
              <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
              <li className="nav-item"><a className="nav-link" href={DOWNLOADLINK}>Download</a></li>
              </ul>
          </div>
          </div>
      </nav>
      <div className="py-3 bg-terra">
        <div className="container pt-lg-5">
          <div className="row h-100">
            <div className="col">
              <h1><img src="/assets/img/portfolio/fullsize/logo.svg" width="350px" alt="TERRA" /></h1>
            </div>
            <div className="col">
              <div className="card">
              <div className="card-header text-terra">Login</div>
              <div className="card-body">
                <form onSubmit={onSubmit}>
                  <div className="">
                      <label htmlFor="email" className="control-label">Email</label>
                      <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="YourEmail@example.com"
                          onChange={onChange}
                          value={email}
                          className="form-control"
                          required="required"
                      />
                  </div>
                  <div className="campo-form form-group">
                      <label htmlFor="password" className="control-label">Password</label>
                      <input
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Your Password"
                          onChange={onChange}
                          value={password}
                          className="form-control"
                          required="required"
                      />
                  </div>
                  <div className="campo-form text-center">
                      <input type="submit" className="btn btn-primary" value="Login" />
                  </div>
                </form>
                <div className="mt-2 text-center">
                  <p>New to TERRA? <Link to={'/signup'} className='enlace-cuenta'>Create your account</Link></p>
                </div>
                <Contact />
              </div>
              </div>
              </div>
              </div>
              </div>
        </div>
        <Footer />
      </Fragment>
    );
};

Login.propTypes = {

};

export default Login;
